const constants = {
    LOGIN_BUTTON_CONTENT: "Login",
    CREATE_ORGANIZATION: "Create an organization now.",
    NO_ORGANIZATION: "Don't have an organization?",
    DASHBOARD_PAGE_TITLE: "Dashboard",
    DASHBOARD_TABLE_TITLE: "PARTNERS LIST",
    UNLOCK_USER_TITLE: "UNLOCK USER",
    FEATURE_PAGE_TITLE: "Service Features",
    NEW_FEATURE_PAGE_TITLE: "Create a New Feature",
    EDIT_FEATURE_PAGE_TITLE: "Edit Feature",
    PRODUCT_PAGE_TITLE: "Service Products",
    NEW_PRODUCT_PAGE_TITLE: "Create a New Product",
    EDIT_PRODUCT_PAGE_TITLE: "Edit Product",
    DASHBOARD_STATUS_TITLE: "Select Approval Status :",
    DASHBOARD_STATUS_TITLE_PENDING_APPROVAL: "PENDING_APPROVAL",
    DASHBOARD_STATUS_TITLE_PENDING_ACTIVATION: "PENDING_ACTIVATION",
    DASHBOARD_STATUS_TITLE_NOT_APPROVED: "NOT_APPROVED",
    DASHBOARD_STATUS_LABEL_PENDING_APPROVAL: "Pending Approval",
    DASHBOARD_STATUS_LABEL_PENDING_ACTIVATION: "Pending Activation",
    DASHBOARD_STATUS_LABEL_NOT_APPROVED: "Rejected",
    DASHBOARD_STATUS_ACCEPTED: "ACCEPTED",
    DASHBOARD_STATUS_REJECTED: "REJECTED",
    DASHBOARD_BUTTON_ACCEPT: "Accept",
    DASHBOARD_BUTTON_REJECT: "Reject",
    PROJECT_DUCLO_TITLE: "Duclo",
    PROJECT_MEGATRON_TITLE: "Hanwha Vision",
    SIGN_IN_PAGE_TITLE_MEGATRON: "Sign in to Hanwha Vision Cloud",
    SIGN_IN_PAGE_TITLE_DUCLO: "Sign in to Duclo",
    AGREES_MEGATRON_CONTENT: "You agree to Hanwha Vision Cloud's",
    AGREES_DUCLO_CONTENT:"You agree to Duclo's",
    PROJECT_PAGE_TITLE_MEGATRON: "Hanwha Vision - System Admin App",
    PROJECT_PAGE_TITLE_DUCLO: "Duclo - System Admin App"
}

export default constants;
