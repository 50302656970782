import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import { useTable, useSortBy, usePagination } from 'react-table';
import * as Icon from 'react-bootstrap-icons';
import {Link, useLocation} from 'react-router-dom';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import BreadcrumbList from '../../components/common/BreadcrumbList';
import constants from '../../helpers/en';
import { requestHeader } from "../../helpers/CommonUtils";
import axios from 'axios';

function ServiceFeatures() {

    const location = useLocation();
    const breadList = [
      {url: "/features", title: constants.FEATURE_PAGE_TITLE}
    ];

    const [show, setShow] = useState(false);
    const [newEntitle, setNewEntitle] = useState(true);
    const [userMsg, setUserMsg] = useState('');
    const [serviceData, setServiceData] = useState([]);
    const [serviceFeatures, setServiceFeatures] = useState([]);

    useEffect(() => {
        fetchData();
    }, []); //This will run only once

    const fetchData = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/features`, requestHeader()).then(res => {
          let response = res.data;
          if ( response.meta.code === 200 ){
            setServiceFeatures(response.data.featureTypes);
            // setServiceData(response.data.features);
            let features = response.data.features;
            let featureTypes = response.data.featureTypes;
            var key = "featureTypeId";
            features = features.map(el => {
              var found = featureTypes.find(s => s[key] === el[key]);
              if (found) {
                  el.featureTypeId = found.featureType;
              }
              return el;
            });
            setServiceData(features);

          } else{
            setUserMsg(response.data.userMsg);
            setShow(true);
          }
        });
      } catch (error) {
        console.error(error);
        setUserMsg(error);
        setShow(true);
      }
    }

    let data = [];

    if( serviceData !== null && serviceData !== undefined ){
      data = serviceData;
    }

    const columns = React.useMemo(
     () => [
       {
         Header: 'Feature Name',
         accessor: 'featureName',
       },
       {
         Header: 'Feature Description',
         accessor: 'featureDesc',
       },
       {
         Header: 'Type',
         accessor: 'featureTypeId'
       },
       {
         Header: 'Status',
         accessor: 'featureStatus'
       },
       {
         Header: '',
         accessor: 'featureId',
         Cell : (props) => {
            return <Link to={`/editFeatures/?featureId=${props.value}`} className="btn-action">Edit</Link>
         }
       },
     ],
     []
   );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    return (
    <div className="App">

      <Header/>

      <Container fluid>
        <Row className="mb-5">
          <Col md={2} lg={2} xl={2} xs={12} className="sidebar text-start" id="duclo-sidebar">
            <div className="sidebar-content" >
              <Sidebar/>
             </div>
           </Col>

           <Col md={10} lg={10} xl={10} xs={12}>

            <Container>
              <div className="page-header mt-4 mb-5">
                <div className="page-title">
                  {constants.FEATURE_PAGE_TITLE}
                </div>
                <Row xs={1} md={2} className="g-4">
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <BreadcrumbList list={breadList} />
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                    <Nav>
                      <Nav.Link href="/newFeatures" className="gray-button">
                        Add New
                      </Nav.Link>
                      <Nav.Link href="/" className="gray-button">
                        Import
                      </Nav.Link>
                      <Nav.Link href="/" className="gray-button">
                        Filter
                      </Nav.Link>
                    </Nav>
                  </Col>
                </Row>
              </div>
            </Container>

             <Container className="h-100">

               <Row className="g-4">
                  {show &&
                    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                      {userMsg}
                    </Alert>
                   }

                   {(location.state !== null && newEntitle) &&
                     <Alert variant="success" className="text-start" onClose={() => setNewEntitle(false)} dismissible>
                       <Icon.CheckSquare height="15" width="15" /> &nbsp;
                       {(location.state.type === "update")
                       ?
                        (`Service Entitlement "${location.state.name}" is added to the system.`)
                       :
                        (`Service Entitlement "${location.state.name}" is updated successfully.`)
                       }
                     </Alert>
                   }

                   {(serviceFeatures.length > 0 && serviceData.length > 0) &&
                     <Table columns={columns} data={data} />
                   }

              </Row>
            </Container>

          </Col>

        </Row>
      </Container>

    </div>

  );
}

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  )

  // We don't want to render all 2000 rows for this example, so cap
  const pageSizeOptions = [10, 20, 30, 40, 50];

  return (
    <>
      <table className="duclo-table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}

                  <span style={{marginLeft: '5px'}}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ( <Icon.SortDown height="15" width="15" />)
                        : ( <Icon.SortUp height="15" width="15" />)
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <Container>
        <Row className="g-4">
          <Col md={6} lg={6} xl={6} xs={6} className="text-start">
            <div>Showing 1 to 10 of {rows.length} entries.</div>
          </Col>
          <Col md={6} lg={6} xl={6} xs={6}>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>{' '}
                <span>
                  &nbsp;
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                  &nbsp;
                </span>
                {/*<span>
                  | Go to page:{' '}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                  />
                </span>{' '}
                */}
                <select
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ServiceFeatures;
